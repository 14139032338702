import {
    Aggregate,
    ColumnDirective,
    ColumnsDirective,
    CommandClickEventArgs,
    CommandColumn,
    CommandModel,
    Edit,
    ExcelExport,
    Filter,
    FilterSettingsModel,
    GridComponent,
    Inject,
    PdfExport,
    Resize,
    SelectionSettingsModel,
    Sort,
    Toolbar,
    ToolbarItems,
    Page,
    SearchSettingsModel,
    SearchEventArgs,
    Search
} from '@syncfusion/ej2-react-grids';
import { Predicate, Query } from '@syncfusion/ej2-data';
import React, { useCallback, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { loadFromApi } from '../../../api/baseApi';
import {
    getBankCodes,
    getDisableChanges,
    getIntent,
    getRejectData,
    updateAccountData,
    updateAmountData,
    updateBankCodesData,
    getIntentByTransactions
} from '../../../api/intentApi';
import { useCommonGridOptions, vaWrapper } from '../../../utils/gridUtils';
import { camelCaseToString } from '../../../utils/stringUtils';
import LoadingDisplay from '../../common/LoadingDisplay';
import MessagePopup from '../../common/MessagePopup';
import * as ej2ReactCharts from '@syncfusion/ej2-react-charts';
import { GridViewContext } from '../../GridViewService';
import { AuthContext } from '../../../authentication/authContext';
import { updateChecklist } from '../../../api/homeApi';
import * as XLSX from 'xlsx';
import BulkCorrections from '../BulkCorrections/bulkCorrections';
import { schedulePaymentsEnabled } from '../../../api/scheduledPaymentsApi';
import { topLevelPages } from '../../../pages';
import { useNavigation } from '../../../hooks/useNavigation';
export const IntentPage = () => {
    const { addToast } = useToasts();
    const { currentView } = React.useContext(GridViewContext);
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );
    const [bulkUpdate, setBulkUpdate] = useState<any>(undefined);
    const [bulkUpdateApiLoaded, setBulkUpdateApiLoaded] = useState<any>(undefined);
    const [excelData, setExcelData] = useState<any>(undefined);
    const [intent, setIntent] = useState<any[]>([]);
    const [banks, setBanks] = useState([]);
    const [codes, setBankCodes] = useState<any[]>([]);
    const [reject, setReject] = useState(false);
    const [rejectReason, setRejectReason] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [updatedIntent, setUpdatedIntent] = useState([]);
    const [pieData, setPieData] = useState([]);
    const [columns, setColumns] = useState<any>([]);
    const [creditorName, setCreditorName] = useState('');

    const { loggedInUser } = React.useContext(AuthContext);
    let selectedCreditorId = localStorage.getItem('id') ?? loggedInUser?.creditorId;
    const isAchCreditor = loggedInUser?.creditorId != "77" ? true : false;
    const goToPage = useNavigation();
        useEffect(()=> {
            if(loggedInUser && !isAchCreditor && !loggedInUser?.role?.includes('Superuser')){
                goToPage(topLevelPages.scheduledPayments);
            }
        }, [])
    useEffect(() => {
        let data: any = {};
        let columns = Object.values(intent)[0] ? Object.keys(Object.values(intent)[0]) : [];
        columns = columns.filter(col => col != 'userID');
        columns.length > 0 && setColumns(columns);
        intent.map((row: any) => {
            let amnt = Number(row.amount.replace('$', '').replace(',', ''));
            if (data.hasOwnProperty(row.bankCode)) {
                data[row.bankCode] = amnt + data[row.bankCode];
            } else {
                data[row.bankCode] = amnt;
            }
        });
        setUpdatedIntent(data);
        let chartData: any = Object.keys(data).map(key => {
            return {
                code: key,
                value: data[key]
            };
        });
        setCreditorName(intent?.[0]?.creditorName ?? '');
        setPieData(chartData);
    }, [intent]);
    const [isAccountUpdated, setIsAccountUpdated] = useState(false);
    const updateAccount = (data: any) => {
        setLoading(true);
        const IntentPageApiWrapper = loadFromApi(setApiError);
        const loadUpdateAccountData = async () => {
            await IntentPageApiWrapper(() => updateAccountData(data), setIsAccountUpdated);
            loadCompanyPageData();
        };
        loadUpdateAccountData();
        const loadCompanyPageData = async () => {
            const intentLoad = await IntentPageApiWrapper(
                () => getIntent(selectedCreditorId),
                setIntent
            );
        };

        setLoading(false);
    };

    const [isAmounttUpdated, setIsAmountUpdated] = useState(false);
    const updateAmount = (data: any) => {
        const IntentPageApiWrapper = loadFromApi(setApiError);
        const loadUpdateAmountData = async () => {
            await IntentPageApiWrapper(
                () => updateAmountData({ ...data, userID: userId }),
                setIsAmountUpdated
            );
            loadCompanyPageData();
        };
        loadUpdateAmountData();
        const loadCompanyPageData = async () => {
            const intentLoad = await IntentPageApiWrapper(
                () => getIntent(selectedCreditorId),
                setIntent
            );
        };
    };

    const [pageDisabled, setDisabled] = useState(false);
    const [enableBulkCorrections, setEnableBulkCorrections] = useState(false);
    useEffect(() => {
        setLoading(true);
        const IntentPageApiWrapper = loadFromApi(setApiError);
        const loadCompanyPageData = async () => {
            await IntentPageApiWrapper(() => getIntent(selectedCreditorId), setIntent);
        };
        loadCompanyPageData();
        const loadBankCodes = async () => {
            await IntentPageApiWrapper(() => getBankCodes(selectedCreditorId), setBankCodes);
        };
        loadBankCodes();
        const disable = async () => {
            await IntentPageApiWrapper(() => getDisableChanges(selectedCreditorId), setDisabled);
            setLoading(false);
        };
        disable();
        const bulkCorrections = async () => {
            await IntentPageApiWrapper(() => schedulePaymentsEnabled(), setEnableBulkCorrections);
            setLoading(false);
        };
        bulkCorrections();
    }, [setApiError]);
    useEffect(() => {
        let bankCodess: any = codes.map((code: any) => {
            return code.reliantBankCode;
        });
        setBanks(bankCodess);
    }, [codes]);

    useEffect(() => {
        setSelectedValue(banks[0]);
    }, [banks]);
    const [isRejected, setIsRejected] = useState(false);
    const [modalType, setModalType] = useState('');
    const [editedRow, setEditedRow] = useState({});
    const [bankData, setBankData] = useState({});
    useEffect(() => {
        setIsRejected(false);
        if (reject) {
            const IntentPageApiWrapper = loadFromApi(setApiError);
            const rejectData = async () => {
                await IntentPageApiWrapper(
                    () =>
                        getRejectData({
                            settlementId: id,
                            changedBy: loggedInUser?.userId,
                            rejectReason: rejectReason,
                            userId: userId
                        }),
                    setIsRejected
                );
            };
            rejectData();
        }
    }, [reject]);

    useEffect(() => {
        if (isRejected) {
            let data: any = intent.map((col: any) => {
                if (id && col.transactionID === id) {
                    col.rejected = true;
                }
                return col;
            });
            setRejectValue('hy');
            setIntent(data);
        }
    }, [isRejected]);

    const onRejectClick = (args?: any) => {
        if (!args.rejected) {
            setShowDialog(true);
            setModalType('Reject');
            if (args?.transactionID) {
                setId(args?.transactionID);
                setUserId(args?.userID);
            }
        }
    };

    const onEditClick = (args?: any) => {
        setEditedRow(args);
        setModalType('Edit');
        setShowDialog(true);
    };
    const [rejectValue, setRejectValue] = useState('Reject');

    const rejectCommand: CommandModel[] = [
        {
            title: 'Reject',

            buttonOption: { content: rejectValue, cssClass: 'e-btn btn-reject' }
        }
    ];

    const editCommand: CommandModel[] = [
        {
            title: 'Edit',
            type: 'Edit',
            buttonOption: {
                cssClass: 'e-outline edit-icon'
            }
        }
    ];
    const selectionSettings: SelectionSettingsModel = { type: 'Multiple', checkboxOnly: true };

    const [initial, setInitial] = useState(true);
    const [id, setId] = useState('');
    const [userId, setUserId] = useState('');
    let dataBound = () => {
        if (gridOptions.ref.current && currentView === 2) {
            gridOptions.ref.current.autoFitColumns([]);
        }
    };

    const onCommandClick = (args?: CommandClickEventArgs) => {
        if (args && args.rowData && args.target) {
            if (args.target.className.includes('edit-icon')) {
                setEditedRow(args.rowData);
                setUserId((args.rowData as any).userID);
                setModalType('Edit');
                setShowDialog(true);
            } else {
                if (!(args.rowData as any).rejected) {
                    setShowDialog(true);
                    setModalType('Reject');
                    if ((args.rowData as any).transactionID) {
                        setId((args.rowData as any).transactionID);
                    }
                }
            }

            const consumerData = args.rowData;
        }
    };
    var exportFileName =
        creditorName +
        '_Next Day Payments_' +
        new Date().getMonth() +
        '_' +
        new Date().getDate() +
        '_' +
        new Date().getFullYear();
    const gridOptions: any = useCommonGridOptions(exportFileName);
    const gridOptions1: any = useCommonGridOptions('');
    const gridOptions2: any = useCommonGridOptions('');
    const gridOptions3: any = useCommonGridOptions('');

    const handleFileUpload = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = e => {
                const binaryStr = e?.target?.result;
                const workbook = XLSX.read(binaryStr, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet);
                setExcelData(jsonData);
                let transactionIds = '';
                jsonData.forEach((element: any) => {
                    transactionIds = transactionIds + element?.TransID.toString() + ',';
                });
                transactionIds = transactionIds.substring(0, transactionIds.length - 1);
                const loadBulkUpdateData = async () => {
                    const IntentPageApiWrapper = loadFromApi(setApiError);
                    await IntentPageApiWrapper(
                        () => getIntentByTransactions(selectedCreditorId, transactionIds),
                        setBulkUpdateApiLoaded
                    );
                };
                loadBulkUpdateData();
            };
            reader.readAsBinaryString(file);
        }
    };

    useEffect(() => {
        if (bulkUpdateApiLoaded && excelData) {
            importCorrectionInfo(excelData, bulkUpdateApiLoaded);
        }
    }, [bulkUpdateApiLoaded, excelData]);

    const importCorrectionInfo = (jsonData: any, bulkUpdateCorrections: any) => {
        const trimmedJsonData = jsonData.map((item: any) => {
            const trimmedItem: any = {};
            for (const key in item) {
            if (item.hasOwnProperty(key)) {
                trimmedItem[key.trim()] = item[key];
            }
            }
            return trimmedItem;
        });
        const bulkUpdateData = bulkUpdateCorrections.map((data: any) => {
            const jsonDataItem = trimmedJsonData.find((item: any) => item.TransID == data.transactionId);
            return {
                ...data,
                newPayeeAccountNumber: String(
                    jsonDataItem?.['Correct Account Number'] || data.newPayeeAccountNumber
                ),
                updatePayeeAccountNumber:
                    jsonDataItem?.['Correct Account Number'] &&
                    jsonDataItem?.['Correct Account Number'] !== ''
                        ? true
                        : false,
                newDepositAmount: jsonDataItem?.['Correct Deposit Amount'] || data.newDepositAmount,
                updateDepositAmount:
                    jsonDataItem?.['Correct Deposit Amount'] &&
                    jsonDataItem?.['Correct Deposit Amount'] !== 0 &&
                    jsonDataItem?.['Correct Deposit Amount'] !== ''
                        ? true
                        : false,
                rejectPayment: jsonDataItem?.['Active'].toUpperCase() === 'Y' ? false : true,
                rejectReason: jsonDataItem?.['Reject Reason'] || data.rejectReason,
                newReferenceNumber: String(
                    jsonDataItem?.['Correct Reference'] || data.newReferenceNumber
                ),
                updateReferenceNumber:
                    jsonDataItem?.['Correct Reference'] &&
                    jsonDataItem?.['Correct Reference'] !== ''
                        ? true
                        : false
            };
        });
        setBulkUpdate(bulkUpdateData);
    };
    const formatRejectedValue = (field: string, data?: any) => {
        let val = '';
        if (data) {
            val = data['rejected'] === true ? 'Reject' : 'Rejected';
        }
        setRejectValue(val);
        return val;
    };
    const [loading, setLoading] = useState(false);
    const [enableConfirm, setEnableConfirm] = useState(false);
    const [settlementIds, setSettlementIds] = useState<any>([]);
    const onRowSelected = () => {
        let ref = gridOptions.ref;
        if (ref.current?.getSelectedRecords() && ref.current?.getSelectedRecords().length > 0) {
            setEnableConfirm(true);
        } else {
            setEnableConfirm(false);
        }
    };

    const searchOptions: SearchSettingsModel = { fields: ['transactionID'], key: '' };
    const toolbarOptions: ToolbarItems[] = ['ExcelExport', 'PdfExport', 'CsvExport', 'Search'];

    let values: any;
    let key = '';
    let refresh = false;
    let removeQuery = false;
    let valueAssign = false;

    const actionBegin = (args: SearchEventArgs) => {
        if (args.requestType == 'searching') {
            const keys = args?.searchString?.split(',');
            var flag = true;
            var predicate: any;
            if (keys && keys?.length > 1) {
                if (gridOptions.ref.current.searchSettings.key !== '') {
                    values = args.searchString;
                    keys.forEach(key => {
                        key = key.trim();
                        gridOptions.ref.current.getColumns().forEach((col: any) => {
                            if (key.length > 1) {
                                if (flag) {
                                    predicate = new Predicate(col.field, 'contains', key, true);
                                    flag = false;
                                } else {
                                    var predic = new Predicate(col.field, 'contains', key, true);
                                    predicate = predicate.or(predic);
                                }
                            }
                        });
                    });
                    gridOptions.ref.current.query = new Query().where(predicate);
                    gridOptions.ref.current.searchSettings.key = '';
                    refresh = true;
                    valueAssign = true;
                    removeQuery = true;
                    gridOptions.ref.current.refresh();
                }
            }
        }
    };

    const actionComplete = (args: SearchEventArgs) => {
        if (args.requestType === 'refresh' && valueAssign) {
            if (gridOptions?.ref?.current)
                (
                    document.getElementById(
                        gridOptions?.ref?.current?.element.id + '_searchbar'
                    ) as any
                ).value = values;
            valueAssign = false;
        } else if (
            gridOptions?.ref?.current &&
            (document.getElementById(gridOptions?.ref?.current?.element.id + '_searchbar') as any)
                ?.value === '' &&
            args.requestType === 'refresh' &&
            removeQuery
        ) {
            gridOptions.ref.current.query = new Query();
            removeQuery = false;
            gridOptions.ref.current.refresh();
        }
    };

    const onRowDataBound = (args?: any) => {
        if (args && args.data) {
            let data = args.data as any;
            if (pageDisabled) {
                args.row?.getElementsByClassName('e-editbutton')[0].classList.add('disabled');
            }
        }
    };
    const saveBankCodes = async () => {
        let ref = gridOptions.ref;
        let ids: any = [];
        if (ref.current?.getSelectedRecords() && ref.current?.getSelectedRecords().length > 0) {
            ids = ref.current?.getSelectedRecords().map((record: any) => {
                return record.transactionID;
            });
        }

        const IntentPageApiWrapper = loadFromApi(setApiError);
        await IntentPageApiWrapper(
            () =>
                updateBankCodesData({
                    settlementID: ids,
                    reliantBankCode: selectedValue,
                    creditorID: selectedCreditorId
                }),
            setIsAccountUpdated
        );
        const loadCompanyPageData = async () => {
            const intentLoad = await IntentPageApiWrapper(
                () => getIntent(selectedCreditorId),
                setIntent
            );
        };
        loadCompanyPageData();
    };
    const filterOptions: FilterSettingsModel = {
        type: 'Excel'
    };
    const bankCodes = ['Default', 'City'];
    const [selectedValue, setSelectedValue] = useState('');
    const [graph, setGraph] = useState(false);
    const [completeResult, setCompleteResult] = useState<any>({});
    const { allowExcelExport, allowPdfExport, toolbarClick, pdfQueryCellInfo, excelQueryCellInfo } =
        gridOptions;

    const apiError = useCallback(
        (error?: string) =>
            (error == '' || error) &&
            addToast(
                'We are currently unable to complete verification. Please contact our support team for more information.',
                { appearance: 'error', autoDismiss: true }
            ),
        [addToast]
    );
    const onComplete = () => {
        const HomePageApiWrapper = loadFromApi(apiError);
        const IntentPageApiWrapper = loadFromApi(setApiError);
        const saveUserData = async () => {
            setLoading(false);
            await HomePageApiWrapper(
                () =>
                    updateChecklist({
                        completedDate: new Date(),
                        creditorID: localStorage.getItem('id') ?? loggedInUser?.creditorId,
                        creditorName: creditorName,
                        paymentDate: new Date(),
                        verificationComplete: true,
                        notifyCreditor: false
                    }),
                setCompleteResult
            );
            const disable = async () => {
                await IntentPageApiWrapper(
                    () => getDisableChanges(selectedCreditorId),
                    setDisabled
                );
                setLoading(false);
                let intentData = intent;
                setIntent([]);
                setTimeout(() => {
                    setIntent(intentData);
                }, 1000);
            };
            disable();
        };
        saveUserData();
    };
    return (
        <>
            {' '}
            <div className="intent-tag">
                <div>
                    <h3 className="intent-name">Creditor Name :</h3> <span>{creditorName}</span>
                </div>
                <button
                    className={`complete-btn ${pageDisabled ? 'disabled' : 'enabled'}`}
                    onClick={onComplete}
                >
                    {' '}
                    Verification Complete
                </button>
            </div>
            <div className="sticky-banner">Changes cannot be made after 4:20 EST</div>
            {loading ? (
                <LoadingDisplay />
            ) : (
                <div className="intent-page">
                    {bulkUpdate === undefined && (
                        <>
                            <div className="graph-btn">
                                <h3>Next Day Payment Summary</h3>
                                <button onClick={() => setGraph(!graph)}>Summary Dashboard</button>
                            </div>
                            {graph && (
                                <ej2ReactCharts.AccumulationChartComponent
                                    id="pie-chart"
                                    title="Deposit Statistics"
                                >
                                    <Inject
                                        services={[
                                            ej2ReactCharts.AccumulationLegend,
                                            ej2ReactCharts.PieSeries,
                                            ej2ReactCharts.AccumulationTooltip,
                                            ej2ReactCharts.AccumulationDataLabel
                                        ]}
                                    />
                                    <ej2ReactCharts.AccumulationSeriesCollectionDirective>
                                        <ej2ReactCharts.AccumulationSeriesDirective
                                            dataSource={pieData}
                                            xName="code"
                                            yName="value"
                                            explode
                                            explodeOffset="10%"
                                            explodeIndex={0}
                                            dataLabel={{
                                                visible: true,
                                                position: 'Inside',
                                                name: 'text',
                                                font: { fontWeight: '600' }
                                            }}
                                            radius="70%"
                                        />
                                    </ej2ReactCharts.AccumulationSeriesCollectionDirective>
                                </ej2ReactCharts.AccumulationChartComponent>
                            )}
                            <div className="summary-block">
                                {codes.map((code, index) => (
                                    <div
                                        key={index}
                                        className={`card ${
                                            updatedIntent[code?.reliantBankCode] ||
                                            code?.reliantBankCode === 'Default'
                                                ? ''
                                                : 'no-data'
                                        }`}
                                    >
                                        <h3>
                                            <span>{code?.reliantBankCode}</span>
                                            <span
                                                className="e-icons e-circle-info"
                                                onClick={() => {
                                                    setShowDialog(true);
                                                    setModalType('Bank');
                                                    setBankData(code);
                                                }}
                                            />
                                        </h3>
                                        <p>
                                            $
                                            {updatedIntent[code?.reliantBankCode]
                                                ? Number(
                                                      updatedIntent[code?.reliantBankCode]
                                                  ).toFixed(2)
                                                : 0.0}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}

                    {bulkUpdate === undefined && (
                        <div className="deposit-details">
                            <h3>Next Day Payment Details</h3>
                            <div>
                                {enableBulkCorrections && (
                                    <>
                                        <label>Bulk Corrections: </label>
                                        <input
                                            className="fileUploader"
                                            type="file"
                                            accept=".xlsx, .xls"
                                            onChange={handleFileUpload}
                                        />
                                    </>
                                )}
                                {banks.length > 0 && (
                                    <>
                                        <label>Bank Code: </label>
                                        <select
                                            onChange={e => setSelectedValue(e.target.value)}
                                            value={selectedValue}
                                        >
                                            {banks.map((bank, index) => (
                                                <option key={index} value={bank}>
                                                    {bank}
                                                </option>
                                            ))}
                                        </select>
                                        <button
                                            type="button"
                                            className={`confirm-btn ${
                                                pageDisabled ? 'disabled' : 'enabled'
                                            }`}
                                            disabled={!enableConfirm || pageDisabled}
                                            onClick={saveBankCodes}
                                        >
                                            Confirm
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="intent-grid">
                        {bulkUpdate === undefined ? (
                            <>
                                <span
                                    className="e-icons e-close serach-clear"
                                    onClick={() => {
                                        if (gridOptions.ref.current) {
                                            gridOptions.ref.current.searchSettings.key = '';
                                            const searchBar = document.getElementById(
                                                `${gridOptions.ref.current.element.id}_searchbar`
                                            );
                                            if (searchBar) {
                                                (searchBar as HTMLInputElement).value = '';
                                            }
                                            gridOptions.ref.current.refresh();
                                        }
                                    }}
                                ></span>
                                <GridComponent
                                    searchSettings={searchOptions}
                                    width={'100%'}
                                    actionComplete={actionComplete}
                                    allowExcelExport={allowExcelExport}
                                    pdfQueryCellInfo={pdfQueryCellInfo}
                                    excelQueryCellInfo={excelQueryCellInfo}
                                    allowPdfExport={allowPdfExport}
                                    actionBegin={actionBegin}
                                    toolbar={toolbarOptions}
                                    toolbarClick={toolbarClick}
                                    ref={gridOptions.ref}
                                    allowFiltering
                                    allowSorting
                                    filterSettings={filterOptions}
                                    rowHeight={currentView === 3 ? 20 : 40}
                                    selectionSettings={selectionSettings}
                                    rowSelected={onRowSelected}
                                    rowDeselected={onRowSelected}
                                    rowDataBound={onRowDataBound}
                                    dataBound={dataBound}
                                    commandClick={onCommandClick}
                                    dataSource={intent}
                                    clipMode="EllipsisWithTooltip"
                                >
                                    {intent.length > 0 && (
                                        <ColumnsDirective>
                                            <ColumnDirective
                                                field="select"
                                                type="checkbox"
                                                width="60"
                                                textAlign="Left"
                                                allowFiltering={false}
                                            />
                                            <ColumnDirective
                                                headerText=""
                                                field="btnEdit"
                                                commands={editCommand}
                                                textAlign="Left"
                                                width="70"
                                                headerTextAlign="Left"
                                                allowFiltering={false}
                                            />
                                            {columns
                                                .filter((key: any) => key === 'rejected')
                                                .map((key: any, index: number) => (
                                                    <ColumnDirective
                                                        key={index}
                                                        field={key}
                                                        allowFiltering
                                                        headerText=""
                                                        width={
                                                            key === 'rejected' ? '100px' : 'auto'
                                                        }
                                                        template={
                                                            key === 'rejected'
                                                                ? (props: any) => (
                                                                      <button
                                                                          className={
                                                                              props['rejected']
                                                                                  ? 'rejected-icon'
                                                                                  : 'reject-icon'
                                                                          }
                                                                          onClick={() =>
                                                                              onRejectClick(props)
                                                                          }
                                                                          disabled={pageDisabled}
                                                                      >
                                                                          {props['rejected']
                                                                              ? 'Rejected'
                                                                              : 'Reject'}
                                                                      </button>
                                                                  )
                                                                : undefined
                                                        }
                                                    />
                                                ))}
                                            {columns
                                                .filter(
                                                    (key: any) =>
                                                        key !== 'creditorName' && key !== 'rejected'
                                                )
                                                .map((key: any, index: number) => (
                                                    <ColumnDirective
                                                        key={index}
                                                        field={key}
                                                        allowFiltering
                                                        headerText={camelCaseToString(key).replace(
                                                            /^\w/,
                                                            c => c.toUpperCase()
                                                        )}
                                                        width="auto"
                                                    />
                                                ))}
                                        </ColumnsDirective>
                                    )}
                                    <Inject
                                        services={[
                                            Edit,
                                            Search,
                                            CommandColumn,
                                            PdfExport,
                                            ExcelExport,
                                            Toolbar,
                                            Filter,
                                            Aggregate,
                                            Resize,
                                            Sort
                                        ]}
                                    />
                                </GridComponent>
                            </>
                        ) : (
                            <BulkCorrections
                                bulkUpdate={bulkUpdate}
                                resetBulkUpdate={() => setBulkUpdate(undefined)}
                            />
                        )}
                    </div>
                </div>
            )}
            {showDialog && (
                <MessagePopup
                    requireConfirmation={modalType === 'Reject' ? true : false}
                    message={
                        modalType === 'Reject'
                            ? 'Are you sure you want to reject this payment?'
                            : undefined
                    }
                    showDialog={showDialog}
                    type={modalType}
                    updateAccount={updateAccount}
                    updateAmount={updateAmount}
                    data={modalType === 'Edit' ? editedRow : bankData}
                    setShowDialog={val => {
                        setShowDialog(val);
                    }}
                    onConfirmClick={(reason?: string) => {
                        setReject(false);
                        if (modalType !== 'Edit') {
                            setRejectReason(reason ?? '');
                            setReject(true);
                        }
                        setShowDialog(false);
                    }}
                    onCancelClick={() => {
                        if (modalType !== 'Edit') {
                            setReject(false);
                        }
                        setShowDialog(false);
                    }}
                ></MessagePopup>
            )}{' '}
        </>
    );
};
function goToPage(intent: any) {
    throw new Error('Function not implemented.');
}

