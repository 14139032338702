import {
    AccordionComponent, AccordionItemDirective, AccordionItemsDirective
} from '@syncfusion/ej2-react-navigations';
import * as React from 'react';
import homeIcon from '../../assets/images/home.png';
import intentIcon from '../../assets/images/intent.png';
import depositIcon from '../../assets/images/depositfiles.png';
import scheduledPaymentsIcon from  '../../assets/images/scheduledPayments.png'
import userSettingsIcon from '../../assets/images/usersettings.png';
import { AuthContext } from '../../authentication/authContext';
import { useNavigation } from '../../hooks/useNavigation';
import {
    topLevelPages
} from '../../pages';
import SideNavHeaderLayout from './SideNavHeaderLayout';
import { useToasts } from "react-toast-notifications";
const SideNavLayout = () => {
    const goToPage = useNavigation();
    const open = localStorage.getItem('sideBarOpen') !== 'false' ? true : false;
    const [value, setvalue] = React.useState(open);
    const localStorageSetHandler = function(e: any) {
        if(open !== null){
            setvalue(!open );
        }
    };
    document.addEventListener("hidePanel", localStorageSetHandler, false);
    const {  loggedInUser} = React.useContext(AuthContext);
    const { addToast } = useToasts();
    const superUser = loggedInUser?.role.includes('Superuser') ? true : false
    const setApiError = React.useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );
    const isAchCreditor = loggedInUser?.creditorId != "77" ? true : false;

    return (
         <div className={`layout-first-column ${value ? 'sideBarOpen': ''}`} 
         onMouseEnter={() => {
            if(open === false){
                setvalue(true);               
            }          
         }}
         onMouseLeave={() => {
            if(open === false){    
                setvalue(false);                 
            } 
         }}>
            <AccordionComponent expandMode="Single">
                <AccordionItemsDirective>
                    {/* <AccordionItemDirective
                        header={() => (
                            <SideNavHeaderLayout
                                label={value ? "Home" : '' }
                                icon={homeIcon}
                                onClick={() => goToPage(topLevelPages.home)}
                            />
                        )}
                    /> */}
                    { superUser && <AccordionItemDirective
                        header={() => (
                            <SideNavHeaderLayout
                                label={value ? "Home" : '' }
                                icon={homeIcon}
                                onClick={() => goToPage(topLevelPages.home)}
                            />
                        )}
                    />
                    } 
                     {isAchCreditor && <AccordionItemDirective
                        header={() => (
                            <SideNavHeaderLayout
                                label={value ? "Next Day Payments" : '' }
                                icon={intentIcon}
                                onClick={() => goToPage(topLevelPages.intent)}
                            />
                        )}
                    />}
                     {isAchCreditor && <AccordionItemDirective
                        header={() => (
                            <SideNavHeaderLayout
                                label={value ? "Deposit History" : '' }
                                icon={depositIcon}
                                onClick={() => goToPage(topLevelPages.deposit)}
                            />
                        )}
                    />}
                    <AccordionItemDirective
                        header={() => (
                            <SideNavHeaderLayout
                                label={value ? "Scheduled Payments" : '' }
                                icon={scheduledPaymentsIcon}
                                onClick={() => goToPage(topLevelPages.scheduledPayments)}
                            />
                        )}
                    />
                    <AccordionItemDirective
                        header={() => (
                            <SideNavHeaderLayout
                                label={value ?"User Preferences": ''}
                                icon={userSettingsIcon}
                                onClick={() => {
                                    goToPage(topLevelPages.userSettings)
                                }}
                            />
                        )}
                    />
                </AccordionItemsDirective>
            </AccordionComponent>
        </div>
    );
};

export default SideNavLayout;
