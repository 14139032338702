import React, { useCallback, useState, useContext } from 'react';
import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Inject,
    Toolbar
} from '@syncfusion/ej2-react-grids';
import { camelCaseToString } from '../../../utils/stringUtils';
import { loadFromApi } from '../../../api/baseApi';
import { useToasts } from 'react-toast-notifications';
import { bulkCorrections } from '../../../api/intentApi';
import { AuthContext } from '../../../authentication/authContext';
import LoadingDisplay from '../../common/LoadingDisplay';
const accountNumberCorrectionColumns = [
    'transactionId',
    'clientId',
    'clientName',
    'payeeAccountNumber',
    'newPayeeAccountNumber'
];

const depositAmountCorrectionColumns = [
    'transactionId',
    'clientId',
    'clientName',
    'depositAmount',
    'newDepositAmount'
];

const rejectedTransactionColumns = ['transactionId', 'clientId', 'clientName', 'rejectReason'];

const referenceNumberCorrectionColumns = [
    'transactionId',
    'clientId',
    'clientName',
    'referenceNumber',
    'newReferenceNumber'
];

const BulkCorrections = (props: any) => {
    const { loggedInUser } = useContext(AuthContext);
    const { addToast } = useToasts();
    const [correctionResponse, setCorrectionResponse] = useState<any>(undefined);
    const { bulkUpdate } = props;

    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const apiError = useCallback(
        (error?: string) =>
            (error == '' || error) &&
            addToast(
                'We are currently unable to complete verification. Please contact our support team for more information.',
                { appearance: 'error', autoDismiss: true }
            ),
        [addToast]
    );

    const [loading, setLoading] = useState(false);

    const onSubmit = () => {
        setLoading(true);
        const IntentPageApiWrapper = loadFromApi(setApiError);
        const roleid = localStorage.getItem('id');
        const saveUserData = async () => {
            await IntentPageApiWrapper(
                () => bulkCorrections(roleid ?? loggedInUser?.creditorId, bulkUpdate),
                setCorrectionResponse
            );
            setLoading(false);
        };
        saveUserData();
    };

    return (
        <>
            <div className="correction-response">
                {correctionResponse?.success === false ? (
                    correctionResponse.errorMessage.map((error: any, index: number) => (
                        <div key={index} className="error-message">
                            {error}
                        </div>
                    ))
                ) : correctionResponse?.success === true ? (
                    <div className="success-message">Corrections submitted successfully</div>
                ) : null}
            </div>

            {loading ? <LoadingDisplay /> : <></>}

            {bulkUpdate.some((item: any) => item.updatePayeeAccountNumber) && (
                <GridComponent
                    width={'100%'}
                    dataSource={bulkUpdate.filter(
                        (item: any) => item.updatePayeeAccountNumber !== false
                    )}
                    clipMode="EllipsisWithTooltip"
                    toolbar={[{ text: '<b>Account Number Corrections</b>', align: 'Center' }]}
                    className="bulk-corrections-grid"
                >
                    <ColumnsDirective>
                        {Object.keys(bulkUpdate?.[0])
                            .filter((key: string) => accountNumberCorrectionColumns.includes(key))
                            .map((key: string, index: number) => (
                                <ColumnDirective
                                    key={index}
                                    field={key}
                                    allowFiltering={true}
                                    headerText={camelCaseToString(key).replace(/^\w/, c =>
                                        c.toUpperCase()
                                    )}
                                    width="100px"
                                />
                            ))}
                    </ColumnsDirective>
                    <Inject services={[Toolbar]} />
                </GridComponent>
            )}

            {bulkUpdate.some((item: any) => item.updateDepositAmount) && (
                <GridComponent
                    width={'100%'}
                    dataSource={bulkUpdate.filter(
                        (item: any) => item.updateDepositAmount !== false
                    )}
                    clipMode="EllipsisWithTooltip"
                    toolbar={[{ text: '<b>Deposit Amount Corrections</b>', align: 'Center' }]}
                    className="bulk-corrections-grid"
                >
                    <ColumnsDirective>
                        {Object.keys(bulkUpdate?.[0])
                            .filter((key: string) => depositAmountCorrectionColumns.includes(key))
                            .map((key: string, index: number) => (
                                <ColumnDirective
                                    key={index}
                                    field={key}
                                    allowFiltering={true}
                                    headerText={camelCaseToString(key).replace(/^\w/, c =>
                                        c.toUpperCase()
                                    )}
                                    width="100px"
                                />
                            ))}
                    </ColumnsDirective>
                    <Inject services={[Toolbar]} />
                </GridComponent>
            )}

            {bulkUpdate.some((item: any) => item.rejectPayment) && (
                <GridComponent
                    width={'100%'}
                    dataSource={bulkUpdate.filter((item: any) => item.rejectPayment !== false)}
                    clipMode="EllipsisWithTooltip"
                    toolbar={[{ text: '<b>Transactions to Reject</b>', align: 'Center' }]}
                    className="bulk-corrections-grid"
                >
                    <ColumnsDirective>
                        {Object.keys(bulkUpdate?.[0])
                            .filter((key: string) => rejectedTransactionColumns.includes(key))
                            .map((key: string, index: number) => (
                                <ColumnDirective
                                    key={index}
                                    field={key}
                                    allowFiltering={true}
                                    headerText={camelCaseToString(key).replace(/^\w/, c =>
                                        c.toUpperCase()
                                    )}
                                    width="100px"
                                />
                            ))}
                    </ColumnsDirective>
                    <Inject services={[Toolbar]} />
                </GridComponent>
            )}

            {bulkUpdate.some((item: any) => item.updateReferenceNumber) && (
                <GridComponent
                    width={'100%'}
                    dataSource={bulkUpdate.filter(
                        (item: any) => item.updateReferenceNumber !== false
                    )}
                    clipMode="EllipsisWithTooltip"
                    toolbar={[{ text: '<b>Reference Number Corrections</b>', align: 'Center' }]}
                    className="bulk-corrections-grid"
                >
                    <ColumnsDirective>
                        {Object.keys(bulkUpdate?.[0])
                            .filter((key: string) => referenceNumberCorrectionColumns.includes(key))
                            .map((key: string, index: number) => (
                                <ColumnDirective
                                    key={index}
                                    field={key}
                                    allowFiltering={true}
                                    headerText={camelCaseToString(key).replace(/^\w/, c =>
                                        c.toUpperCase()
                                    )}
                                    width="100px"
                                />
                            ))}
                    </ColumnsDirective>
                    <Inject services={[Toolbar]} />
                </GridComponent>
            )}

            <div className="bulk-corrections">
                <button
                    className={`confirm-btn ${loading ? 'disabled' : 'enabled'}`}
                    onClick={onSubmit}
                    disabled={loading}
                >
                    Submit Corrections
                </button>
                <button className="confirm-btn enabled" onClick={() => window.location.reload()}>
                    Next Day Payments
                </button>
            </div>
        </>
    );
};

export default BulkCorrections;
